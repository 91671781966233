<template>
    <div>
        <Modal :title="title" v-model="isShowModal">
            <template v-slot:modal-content>
                <div class="describe">
                    <p
                        v-for="(des, index) in descriptions"
                        :key="index"
                        v-text="des"
                    />
                </div>

                <div class="header-option-container">
                    <Checkbox
                        name="columnsShowAll"
                        value="all"
                        label="全選"
                        v-model="ColumnsShowAll"
                        @click.stop="$setGaEvent(`selectAll`, 'column-Setting')"
                    />
                    <div class="flex">
                        <Button
                            buttonWord="系統預設"
                            buttonSize="small"
                            buttonStyle="blue-hollow"
                            @click="
                                toggleOption('default'),
                                    $setGaEvent(
                                        `toggleToDefault`,
                                        'column-Setting'
                                    )
                            "
                        />
                    </div>
                </div>

                <div class="table">
                    <div class="drag-header row">
                        <div
                            v-for="(column, index) in header"
                            v-text="column.label"
                            :key="index"
                            :style="getRowStyle(column)"
                        />
                    </div>

                    <div class="drag-body">
                        <div
                            v-for="(element, index) in unmovableColumns"
                            :key="index"
                            class="row disabled"
                        >
                            <div class="row-data">
                                <Checkbox
                                    :value="element?.value"
                                    :style="getRowStyle(header.show)"
                                    :disabled="element.isUnhide ? true : false"
                                    v-model="columnShowList"
                                />
                                <div
                                    class="label"
                                    :style="getRowStyle(header.label)"
                                    v-text="element.label"
                                />
                                <div
                                    class="icon drag-icon disabled"
                                    :style="getRowStyle(header.drag)"
                                />
                            </div>
                        </div>
                        <draggable
                            v-model="movableColumns"
                            item-key="value"
                            ghost-class="ghost"
                            chosen-class="chosen"
                            handle=".drag-icon"
                            :animation="150"
                            :forceFallback="true"
                            :scrollSensitivity="200"
                            :fallbackTolerance="3"
                            @start="drag = true"
                            @end="drag = false"
                        >
                            <template #item="{ element }">
                                <div class="row">
                                    <div class="row-data">
                                        <Checkbox
                                            :value="element?.value"
                                            :style="getRowStyle(header.show)"
                                            :disabled="
                                                element.isUnhide ? true : false
                                            "
                                            v-model="columnShowList"
                                        />
                                        <div
                                            class="label"
                                            :style="getRowStyle(header.label)"
                                            v-text="element.label"
                                        />
                                        <div
                                            class="icon drag-icon"
                                            :style="getRowStyle(header.drag)"
                                            @mousedown="
                                                $setGaEvent(
                                                    `drag${$firstCapitalized(
                                                        element?.value
                                                    )}`,
                                                    'column-Setting'
                                                )
                                            "
                                            @touchstart.passive="
                                                $setGaEvent(
                                                    `drag${element?.value}`,
                                                    'column-Setting'
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>
            </template>
            <template v-slot:modal-bottom>
                <Button
                    v-if="!isSaveLoading"
                    :buttonWord="
                        optionType === 'custom' ? '已設為預設' : '設為預設值'
                    "
                    :buttonStyle="
                        optionType === 'custom' ? 'hollow' : 'blue-hollow'
                    "
                    :disabled="optionType === 'custom' ? true : false"
                    class="set-custom"
                    @click="savePersonalConfig()"
                />
                <Button
                    v-else-if="isSaveLoading"
                    buttonWord="儲存中"
                    buttonStyle="hollow"
                    iconClass="process-loading-icon"
                    class="download-loading disabled set-custom"
                    :disabled="true"
                />
                <div class="to-right">
                    <Button
                        buttonWord="取消"
                        buttonStyle="grey"
                        @click="isShowModal = false"
                    />
                    <Button
                        buttonWord="套用"
                        buttonStyle="blue"
                        @click="apply()"
                    />
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import _ from 'lodash'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import draggable from 'vuedraggable'
import {
    getPageColumnConfigAPI,
    savePageColumnConfigAPI
} from '@/assets/javascripts/api'

export default {
    name: 'ColumnsSettingModal',
    emits: ['update:modelValue', 'update:columnConfig', 'update:hiddenColumns'],
    components: {
        Modal,
        Button,
        Checkbox,
        draggable
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        columnConfig: {
            type: Object,
            default: function () {
                return {}
            }
        },
        hiddenColumns: {
            type: Number,
            default: 0
        },
        columnsMode: {
            type: String,
            default: null
        },
        defaultColumns: {
            typeof: Object,
            default: function () {
                return {}
            }
        },
        hasColumnSetting: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        reset: function () {
            this.initColumnConfigValue()
        },
        apply: function () {
            this.setColumnConfig()
            this.$setGaEvent(`applySetCol`, 'column-Setting')
            Object.keys(this.columnConfigValue).forEach((column) => {
                if (!this.columnShowList[column]) {
                    this.$setGaEvent(`${column}Hide`, 'hide-Column')
                }
            })
            this.isShowModal = false
            this.$reloadTips()
        },
        getRowStyle: function (col) {
            let style = {}
            if (col.width) style.width = `${col.width}%`
            if (col.position) style.textAlign = col.position
            return style
        },
        setColumnConfig: function () {
            this.setConfigSeqValue()

            this.$emit(
                'update:columnConfig',
                _.cloneDeep(this.columnConfigValue)
            )
        },
        initColumnConfigValue: function () {
            this.columnConfigValue = _.cloneDeep(this.columnConfig)
            Object.keys(this.columnConfigValue).forEach((columnKey, index) => {
                const column = this.columnConfigValue[columnKey]
                column.seq = column.seq ?? index
                column.isVisible = column.isVisible ?? true
                this.columnShowList[columnKey] = column.isVisible
            })
            this.setColumns()
        },
        setColumns: function () {
            const columnConfig = _.cloneDeep(this.columnConfigValue)
            this.unmovableColumns = Object.keys(columnConfig)
                .filter((columnKey) => {
                    return columnConfig[columnKey].isUnmovable
                })
                .map((columnKey) => {
                    return {
                        label: columnConfig[columnKey].label,
                        value: columnKey,
                        isUnhide: columnConfig[columnKey]?.isUnhide
                    }
                })
            const unmovableCount = this.unmovableColumns.length
            this.movableColumns = Object.keys(columnConfig)
                .filter((columnKey) => {
                    return !columnConfig[columnKey].isUnmovable
                })
                .sort((a, b) => columnConfig[a].seq - columnConfig[b].seq)
                .map((columnKey, index) => {
                    return {
                        label: columnConfig[columnKey].label,
                        value: columnKey,
                        isUnhide: columnConfig[columnKey]?.isUnhide,
                        seq: index + unmovableCount
                    }
                })
        },
        getPageColumnConfig: async function () {
            if (!this.hasColumnSetting) return
            const path = this.$route.path
            this.initColumnConfigValue()
            try {
                const res = await getPageColumnConfigAPI(path)
                this.configList = await res.data
                await this.getConfigList()
                await this.setInitialConfig()
                if (
                    this.configList?.customFields ||
                    this.configList?.customFields?.length > 0
                )
                    this.toggleOption('custom')
                else this.toggleOption('default')
                await this.setColumnConfig()

                this.isAlreadyCallGetApi = true
            } catch (error) {
                console.log(error)
                this.$showErrorMessage(
                    '載入設定時出現錯誤，欄位將只顯示系統預設設定',
                    '若持續出現此問題，請聯繫資訊客服'
                )
                this.toggleOption('default')
            } finally {
                setTimeout(() => {
                    this.setColumnConfig()
                }, 200)
            }
        },
        savePersonalConfig: async function () {
            this.isSaveLoading = true
            this.setConfigSeqValue()
            //轉成payload格式
            const config = _.cloneDeep(this.columnConfigValue)
            Object.keys(config).forEach((key) => {
                const order = config[key].seq
                const isVisible = config[key].isVisible ?? true

                config[key] = {
                    name: key,
                    order: order,
                    isVisible: isVisible
                }
            })
            const path = this.$route.path
            const payload = {
                customFields: { type: this.columnsMode, fields: config }
            }

            try {
                const res = await savePageColumnConfigAPI(path, payload)
                const newCustomFields = res.data.customFields
                const type = newCustomFields?.type ?? 'noType'
                this.customList[type] = newCustomFields?.fields
                this.setInitialConfig()
                this.optionType = 'custom'
                this.$setGaEvent(`saveConfig`, 'column-Setting')
            } catch (error) {
                console.log(error)
                this.$showErrorMessage(
                    '儲存時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
                this.$setGaEvent(`saveError`, 'column-Setting')
            } finally {
                this.isSaveLoading = false
            }
        },
        getConfigList: function () {
            const customs = this.configList?.customFields ?? {}
            const defaults = this.configList?.defaultFields ?? {}
            let transToObject = function (configs) {
                return Object.values(configs).reduce((memo, list) => {
                    if (list?.type) {
                        memo[list.type] = list.fields
                    } else {
                        memo['noType'] = list.fields
                    }
                    return memo
                }, {})
            }
            this.customList = transToObject(customs)
            this.defaultList = transToObject(defaults)
        },
        setInitialConfig: function () {
            if (!this.hasColumnSetting) return
            //判斷相同table是否因搜尋條件有不同模式的欄位設定檔
            const customList = this.customList[this.columnsMode ?? 'noType']
            this.customConfigValue = this.transConfigFormat(
                customList ? customList : this.defaultColumns
            )

            this.defaultConfigValue = this.transConfigFormat(
                this.defaultColumns //目前沒用API的defaultFields，預設值使用前端config
            )

            if (customList && !this.isConfirmApiKeys(customList ?? {})) {
                const list = _.cloneDeep(this.defaultColumns)
                Object.assign(list, this.customConfigValue)
                this.customConfigValue = list
                this.toggleOption('custom')
                this.setColumns()
                this.$showErrorMessage(
                    '更新欄位設定檔',
                    '系統有新增或調整欄位設定值，如有欄位狀態和原設定不同，再請您調整，謝謝!'
                )
                this.savePersonalConfig()
                this.$setGaEvent(`customerColumnKeyReset`, 'column-Setting')
            }
        },
        transConfigFormat: function (config) {
            //key須符合前端config檔的key
            const columnConfigValue = _.cloneDeep(this.defaultColumns)
            const hasConfig = Object.keys(config).length > 0
            if (hasConfig) {
                Object.entries(config).forEach(([name, column], index) => {
                    if (columnConfigValue[column?.name ?? name]) {
                        columnConfigValue[column?.name ?? name].isVisible =
                            column?.isVisible ?? true

                        columnConfigValue[column?.name ?? name].seq =
                            column?.order ?? index
                    }
                })
            }

            return columnConfigValue
        },
        toggleOption: function (optionKey = '') {
            this.optionType = optionKey
            if (optionKey === 'default') {
                this.columnConfigValue = _.cloneDeep(this.defaultConfigValue)
            } else if (optionKey === 'custom') {
                this.columnConfigValue = _.cloneDeep(this.customConfigValue)
            }
            Object.keys(this.columnConfigValue).forEach((columnKey) => {
                const column = this.columnConfigValue[columnKey]
                this.columnShowList[columnKey] = column.isVisible
            })
            this.setColumns()
        },
        compareColumnsOptions: function () {
            let isConfirmCustom = true
            const configKeys = Object.keys(this.columnConfigValue)
            configKeys.forEach((key) => {
                //我的自訂
                if (
                    this.columnConfigValue[key]?.isVisible !==
                    this.customConfigValue[key]?.isVisible
                )
                    isConfirmCustom = false
                if (
                    this.columnConfigValue[key]?.seq !==
                    this.customConfigValue[key]?.seq
                )
                    isConfirmCustom = false
            })

            if (isConfirmCustom) this.optionType = 'custom'
            else this.optionType = ''
        },
        setConfigSeqValue: function () {
            const unmovableCount = this.unmovableColumns.length
            this.unmovableColumns.forEach((column, index) => {
                this.columnConfigValue[column.value].seq = index
            })
            this.movableColumns.forEach((column, index) => {
                this.columnConfigValue[column.value].seq =
                    index + unmovableCount
            })
        },

        isConfirmApiKeys: function (ApiConfig = {}) {
            /*驗證API回傳欄位key和前端config的key是否吻合，避免key不一致的欄位無法顯示*/
            let isConfirm = false
            if (typeof ApiConfig !== 'object') ApiConfig = {}
            const ApiConfigKeys = Object.keys(ApiConfig)
            const defaultKeys = Object.keys(this.defaultColumns)
            for (const key of defaultKeys) {
                if (ApiConfigKeys.includes(key)) {
                    isConfirm = true
                } else {
                    isConfirm = false
                    break
                }
            }
            return isConfirm
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        columnsLength: function () {
            return Object.keys(this.columnConfigValue).length
        },
        hiddenCount: function () {
            return Object.values(this.columnConfigValue).filter(
                (column) => column.isVisible === false
            ).length
        },
        title: function () {
            const hideHint =
                this.hiddenCount > 0
                    ? `(已隱藏${this.hiddenCount}/${this.columnsLength})`
                    : ''
            return '欄位顯示設定' + hideHint
        },
        ColumnsShowAll: {
            get() {
                const showList = Object?.values(this.columnShowList) ?? []
                const isSelectedAll = showList.every((isVisible) => isVisible)
                return { all: isSelectedAll }
            },
            set(val) {
                Object.keys(this.columnShowList).forEach((key) => {
                    this.columnShowList[key] = val.all ? true : false
                    //取消全選不能被隱藏的欄位
                    if (this.columnConfigValue[key]?.isUnhide) {
                        this.columnShowList[key] = true
                    }
                })
            }
        },
        hasCustomConfig: function () {
            return this.customList[this.columnsMode ?? 'noType'] ? true : false
        }
    },
    watch: {
        columnShowList: {
            handler() {
                Object.keys(this.columnConfigValue).forEach((col) => {
                    const data = this.columnConfigValue[col]
                    data.isVisible = this.columnShowList[col]
                })
                this.compareColumnsOptions()
            },
            immediate: true,
            deep: true
        },
        isShowModal: {
            handler(newVal) {
                const html = document.querySelector('html')
                if (newVal) {
                    this.$addAllClass(html, 'not-scroll')
                } else {
                    this.$removeAllClass(html, 'not-scroll')
                    window.setTimeout(
                        () => {
                            this.reset()
                        },
                        !newVal ? 200 : 0
                    )
                }
            },
            immediate: true
        },
        hiddenCount: function (val) {
            this.$emit('update:hiddenColumns', val)
        },
        '$route.path': {
            handler() {
                if (this.isAlreadyCallGetApi) this.setInitialConfig()
                else this.getPageColumnConfig()
            },
            deep: true
        },
        columnsMode: function () {
            this.setInitialConfig()
            this.toggleOption('custom')
            this.setColumnConfig()
        },
        movableColumns: function () {
            this.setConfigSeqValue()
            this.compareColumnsOptions()
        },
        defaultColumns: function () {
            if (!this.hasColumnSetting) return
            this.initColumnConfigValue()
            this.setInitialConfig()
            this.toggleOption('custom')
            this.setColumnConfig()
        }
    },
    data() {
        return {
            descriptions: [
                '1. 表格將顯示勾選的欄位，如有想隱藏的欄位，請取消勾選。',
                `2. 按著不放右側拖曳圖案，上下移動改變欄位顯示順序。`,
                `3. 欄位的顯示或是拖曳圖示反灰為不可調整。`
            ],
            header: {
                show: {
                    label: '顯示',
                    position: 'center',
                    width: 20
                },
                label: {
                    label: '欄位名稱',
                    position: 'left',
                    width: 60
                },
                drag: {
                    label: '拖曳',
                    position: 'center',
                    width: 20
                }
            },
            movableColumns: [],
            unmovableColumns: [],
            columnConfigValue: {},
            columnShowList: {},
            drag: false,
            configList: { customFields: [], defaultFields: [] },
            optionType: '',
            customConfigValue: {},
            defaultConfigValue: {},
            customList: {},
            defaultList: {},
            isSaveLoading: false,
            isAlreadyCallGetApi: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getPageColumnConfig()
        })
    }
}
</script>

<style lang="scss" scoped>
.disabled {
    opacity: 1;
}

:deep(.checkbox-container) {
    justify-content: center;
    input {
        margin-left: 16px;
    }
}

.describe {
    margin-bottom: 15px;
    p {
        margin: 0;
        color: $fourth-black;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }
}

.drag {
    text-align: center;
    cursor: pointer;
}

.header-option-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    :deep(.hollow-button) {
        opacity: 0.4;
        background-color: transparent;
    }
}

.label {
    margin-top: auto;
    margin-bottom: auto;
}

.icon {
    width: 20px;
    min-height: 40px;
    background-size: 20px 20px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    cursor: pointer;
    &.disabled {
        opacity: 0.2;
    }
}

.drag-header {
    display: flex;
    align-items: center;
    width: 100%;
    background: $primary-grey;
    color: $sixth-black;
    font-size: 14px;
    font-weight: 600;
    min-height: 40px;
}

.drag-body {
    user-select: none;

    .row {
        min-height: 40px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border: 1px solid $eighth-grey-disabled-light;
        user-select: none;

        .row-data {
            width: 100%;
            display: flex;
        }
    }
}

.ghost {
    background-color: $secondary-white;
    visibility: hidden;
}
.chosen {
    background-color: $primary-grey;
}

:deep(.modal-bottom) {
    justify-content: space-between;
    .to-right {
        margin-left: auto;
    }
    .set-custom {
        width: 110px;
        margin-bottom: 0;
        :deep(button) {
            width: 110px;
        }
    }
    .hollow-button {
        &.disabled {
            background-color: transparent;
        }
    }

    .download-loading {
        :deep(.process-loading-icon) {
            width: 10px;
            height: 10px;
            order: 1;
            align-items: center;
        }
    }
    @media screen and (max-width: 576px) {
        &.to-right {
            display: block;
        }
        .set-custom {
            width: 100%;
            margin-bottom: 30px;
        }
    }
}
</style>
